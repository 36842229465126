<template>
  <div class="empty-restriction">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.empty-restriction {
  display: flex;
  flex-direction: column;
  height: 100svh;
}
</style>
